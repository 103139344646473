import React from "react";
import { Link } from 'react-router-dom';
import HonestarApis from "../../Apis/Api";
import "./index.css";
import { ReactComponent as FooterLogo } from "../../Assets/honestar-footer-logo.svg";
import { useEffect, useState } from "react";
import { handleizeString } from "../../utils/utils.js";

const Footer = () => {
  const [categories, setCategories] = useState([]);
  const getAllCategoriesAndReviews = () => {
    HonestarApis.getAllCategoriesAndReviews()
      .then((data) => {
        let sortedCatArray = data.category.sort((a, b) => b.review - a.review);
        console.log(sortedCatArray)
        setCategories(sortedCatArray.slice(0, 5))
      });
  };
  useEffect(() => {
    getAllCategoriesAndReviews();
  }, [])

  useEffect(() => {
    console.log("categories:", categories);
  }, [categories])

  return (
    <>
      <div className="footer">
        <div className="footer-inner">
          <div className="footer-top">
            <div className="column-1">
              <h3>Quick Links</h3>
              <ul>
                <li><Link to="/"> Home </Link></li>
              </ul>
            </div>
            <div className="column-2">
              <h3>Top 5 Categories</h3>
              <ul>
                {
                  categories?.map((index) => {
                    return (
                      <li onClick={() => {
                        // window.history.replaceState(null, "Honestars", `/category/${handleizeString(index.name)}?id=${index.id}`) 
                        window.location.replace(`/category/${handleizeString(index.name)}?id=${index.id}`);
                      }}> <Link
                      // to={`/category/${handleizeString(index.name)}?id=${index.id}`}
                      >{index.name} </Link> </li>
                    )
                  }
                  )
                }
              </ul>
            </div>
            <div className="column-3">
              <h3>Contact Us</h3>
              <ul>
                <li><Link to="support@omnibrandboost.com"> support@omnibrandboost.com </Link></li>
              </ul>
            </div>
            <div className="column-4">
              <h3>Keep in touch</h3>
              <div className="keep-in-touch-form">
                <input type="text" name="keep_in_email" placeholder="Your Email" />
                <button> Submit </button>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom-inner">
              <div className="honestar-footer-logo">
                <FooterLogo />
              </div>
              <ul className="footer-links">
                {/* <li> <Link to="/terms-and-conditions"> Terms and Conditions </Link> </li>
                <li> | </li> */}
                <li> <Link to="/privacy"> Privacy </Link> </li>
                <li> | </li>
                <li> 2024 OmniBoost </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
