import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { handleizeString } from "../../utils/utils.js";
import { ReactComponent as ReviewIcon } from "../../Assets/reviews-icon.svg";
import { Link } from "react-router-dom";
import CategoryIcon from "./category-icon";
import Skeleton from "@mui/material/Skeleton";

const TopCategories = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    console.log("props.categories", props.categories)
    setCategoryList(props.categories)
    setIsLoading(false);
    console.log("categoryList", categoryList)
  }, []);
  return (
    <>
      <div className="top-categories">
        <div className="top-categories-inner">
          <div className="category-top">
            <h1> Top Categories </h1>
          </div>
          <div className="category-bottom">
            <div className="category-bottom-inner">
              {!isLoading ? (
                categoryList?.map((item, index) => (
                  <Link
                    className="category-item"
                    key={index}
                    to={`/category/${handleizeString(item.name)}?id=${item.id}`}
                  >
                    <div className="icon">
                      <CategoryIcon Icon={handleizeString(item.name)} />
                    </div>
                    <div className="title">
                      <h3> {item.name} </h3>
                    </div>
                    <div className="stats">
                      <span className="brands"> {item.brand} Brands</span>
                      <span className="reviews">
                        {item.review}
                        <ReviewIcon />
                      </span>
                    </div>
                  </Link>
                ))
              ) : (
                <>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                <Skeleton className="skeleton-item"/>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TopCategories;
