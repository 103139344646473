import React, { useState, useEffect } from "react";
import BrandHeader from "./brand-header.js";
import WriteReviewForm from "./write-review-form.js";
import "./index.css";
const WriteReview = () => {
  return (
    <>
      <div className="write-review-page">
        <BrandHeader />
        <WriteReviewForm />
      </div>
    </>
  );
};
export default WriteReview;
