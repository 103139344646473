export const backendUrl = "https://be.wholescale.net"
 
 export function handleizeString(str) {
  // Remove special characters and replace spaces with hyphens
  const handledString = str
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^\w\s-]/g, '') // Remove all non-word characters (except spaces and hyphens)
    .trim() // Trim any leading or trailing spaces
    .replace(/\s+/g, '-') // Replace consecutive spaces with a single hyphen
    .replace(/-{2,}/g, '-'); // Replace multiple consecutive hyphens with a single hyphen

  return handledString;
}

