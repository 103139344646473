import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  Box,
  Select,
  Option,
  MenuItem,
} from "@mui/material";
import { handleizeString } from "../../utils/utils.js";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import { ReactComponent as EmptyStar } from "../../Assets/empty-star.svg";
import Rating from "@mui/material/Rating";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import NoImage from "../../Assets/no-image.svg";
const CategoryList = (props) => {
  // {
  //   title: "Baby Brezza",
  //   image:
  //     "https://cdn.shopify.com/s/files/1/0776/2165/7883/files/Ellipse_9_1.png?v=1690026130",
  //   total_reviews: 20666,
  //   avg_rating: 4.6,
  //   label: "Most Relevant",
  //   social_link: { facebook: "facebook.com", instagram: "instagram.com" },
  // },
  const [totalRecords, setTotalRecords] = useState(20);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [brandsList, setBrandsList] = useState(null);
  const [sortValue, setSortValue] = useState("latest_review");
  // const [brandsListTemp, setBrandsListTemp] = useState(null);
  // const [brandsListSort, setBrandsListSort] = useState(null);
  const [flag, setFlag] = useState(1);
  useEffect(() => {
    console.log("called use effect")
    console.log("props.brandsList", props.brandsList)
        setBrandsList([...props.brandsList]);
  }, [props.brandsList]);
  const handleChange = (event) => {
    if(event.target.value === "reviews_count") {
      let sortBrands = brandsList.sort((a, b) => b.reviews_count - a.reviews_count);
      setBrandsList(sortBrands);
    }
    else {
      // setBrandsList(brandsListTemp);
      props.getCategoryBrandsList()
    }
    setSortValue(event.target.value)
  }
  return (
    <>
      <div className="category-list">
        <div className="category-list-inner">
          <div className="category-list-top">
            <div className="sort-by">
              <FormControl className="sortby-formcontrol">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Select category"
                  defaultValue={"latest_review"}
                  size={"small"}
                value={sortValue}
                onChange={handleChange}
                >
                  <MenuItem value={"latest_review"}>
                    Most recent reviews
                  </MenuItem>
                  <MenuItem value={"reviews_count"}>
                    Highest number of reviews
                  </MenuItem>

                </Select>
              </FormControl>
            </div>
            <div className="category-list-top-right">
              <p className="pagination"> 1 - {brandsList?.length} of {brandsList?.length} Results </p>
            </div>
          </div>
          <div className="category-list-bottom brand-grid">
            {brandsList?.map((brand, index) => (
              <div
                className="brand-grid-item"
                key={index}
              >
                <Link to={`/brands/${handleizeString(brand.name)}?id=${brand.id}`} className="brand-logo">
                  <img
                    src={brand.logo ? brand.logo : NoImage}
                    alt={brand.name}
                  />
                </Link>
                <div className="brand-content">
                  <Link className="brand-uper" to={`/brands/${handleizeString(brand.name)}?id=${brand.id}`}>
                    <h2> {brand.name} </h2>
                    <div className="review-rating">
                      <Rating
                        name="read-only"
                        value={Math.round(brand.reviews_avg)}
                        size="small"
                        icon={<FilledStar fontSize="inherit" />}
                        emptyIcon={<EmptyStar fontSize="inherit" />}
                        readOnly
                      />
                      <p>{brand?.reviews_avg?.toFixed(1)}</p>
                      <p> {brand.reviews_count} Reviews </p>
                    </div>
                  </Link>
                  <div className="social-icons">
                    {brand.insta_url ? <a href={brand.insta_url} target="_blank" rel="noreferrer">  <InstagramIcon />  </a> : null}
                    {brand.facebook_url ? <a href={brand.facebook_url} target="_blank" rel="noreferrer"> <FacebookIcon /> </a> : null}
                    {brand.youtube_url ? <a href={brand.youtube_url} target="_blank" rel="noreferrer">  <YouTubeIcon /> </a> : null}
                    {brand.twitter_url ? <a href={brand.twitter_url} target="_blank" rel="noreferrer"> <TwitterIcon /> </a> : null}
                  </div>
                </div>
                {brand.label ? (
                  <div className="brand-label">
                    <span> {brand?.label} </span>
                  </div>
                ) :
                  null
                }
              </div>
            ))}
            {
              <div style={{ paddingLeft: "20px" }}>
                {brandsList?.length === 0 ? <h3>No Results Found</h3> : null}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryList;
