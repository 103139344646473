import React, { useState, useEffect } from "react";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  Select,
  Option,
  MenuItem,
} from "@mui/material";
const Filters = (props) => {
  const [productValue, setProductValue] = useState("all-products");
  useEffect(() => {
    eventlistners()
  }, []);
  const eventlistners = () => {
    let starElements = document.querySelectorAll(".rating-grid > div");
    starElements?.forEach((item) => {
      item.addEventListener("click", (event) => {
        document.querySelector(".rating-grid > div.active")?.classList.remove("active")
        item?.classList.add("active")
      })
    })
  };
  const handleChange = (event) => {
    setProductValue(event.target.value);
    props.setProductId(event.target.value);
  };
  return (
    <>
      <div className="filter">
        <div className="filter-inner">
          <div className="filter-card-1 card">
            <div className="rating">
              <h3> Rating </h3>
              <div className="rating-grid">
                <div className="rating-grid-item star-all active" onClick={() => { props.setRating(0) }}>
                  <span> Any </span>
                </div>
                <div className="rating-grid-item star-2" onClick={() => { props.setRating(2) }}>
                  <FilledStar />
                  <span> 2+ </span>
                </div>
                <div className="rating-grid-item star-3" onClick={() => { props.setRating(3) }}>
                  <FilledStar />
                  <span> 3+ </span>
                </div>
                <div className="rating-grid-item star-4" onClick={() => { props.setRating(4) }}>
                  <FilledStar />
                  <span> 4+ </span>
                </div>
                <div className="rating-grid-item star-5" onClick={() => { props.setRating(5) }}>
                  <FilledStar />
                  <span> 5 </span>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-card-2 card">
            <label> Products </label>
            <FormControl className="category-formcontrol filter-category-formcontrol product-formcontrol">
              {Object.keys(props.dataFromApi).length > 0 && <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select category"
                defaultValue={"all-products"}
                size={"small"}
                onChange={handleChange}
                value={productValue}
              >
                <MenuItem value={"all-products"}>
                  All Products
                </MenuItem>
                {props.dataFromApi?.product.map((val) => {
                  return (
                    <MenuItem value={val.id} key={val.id}>
                      {val.title}
                    </MenuItem>
                  );
                })}
              </Select>}
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
