import React, { useState, useEffect } from "react";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import { ReactComponent as EmptyStar } from "../../Assets/empty-star.svg";
import { ReactComponent as AddPhoto } from "../../Assets/add-photo.svg";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from "@mui/icons-material/Close";
import Rating from "@mui/material/Rating";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactFileReader from "react-file-reader";
import HonestarApis from "../../Apis/Api";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AlertTitle from '@mui/material/AlertTitle';
import { Grid } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const WriteReviewForm = ({ dataFromApi, showReview, setshowReview, setShowLeaveReviewButton }) => {
  const [loadingButton, setloadingButton] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [imageLimitCheck, setImageLimitCheck] = useState(false);
  const [renderCount, setRenderCount] = useState(1);
  const [blackBoxStr, setBlackBoxStr] = useState('');
  const [productList, setproductList] = useState([]);
  const [reviewForm, setReviewForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    product: '',
    title: '',
    description: '',
    star: '',
    black_box: '',
    client: 0,
    submission_images: [],
    errors: {
      first_name: false,
      last_name: false,
      email: false,
      product: false,
      title: false,
      description: false,
      star: false,
      black_box: false,
      client: false,
      submission_images: false
    }
  }
  );
  const endLoader = () => {
    setloadingButton(false);
  }
  const apiCall = (callback) => {
    setloadingButton(true);
    let descriptionCharacterCheck = false;
    if (reviewForm.description.length > 10) {
      descriptionCharacterCheck = true;
      setReviewForm((prevReviewForm) => ({
        ...prevReviewForm,
        errors: {
          ...prevReviewForm.errors,
          description: false,
        }
      }));
    }
    else {
      descriptionCharacterCheck = false
      setReviewForm((prevReviewForm) => ({
        ...prevReviewForm,
        errors: {
          ...prevReviewForm.errors,
          description: true,
        }
      }));
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const areAllValuesNotNull = Object.values(reviewForm).every(value => value !== '' && value !== undefined);
    if (areAllValuesNotNull && descriptionCharacterCheck) {
      const isValidEmail = emailPattern.test(reviewForm.email);
      if (isValidEmail) {
        setReviewForm((prevReviewForm) => ({
          ...prevReviewForm,
          errors: {
            ...prevReviewForm.errors,
            email: false,
          }
        }));
        HonestarApis.leaveReview(reviewForm).then((data) => {
          handleOpen();
        });
      }
      else {
        setReviewForm((prevReviewForm) => ({
          ...prevReviewForm,
          errors: {
            ...prevReviewForm.errors,
            email: true,
          }
        }));
      }
      //setting the errors false
      for (let x in reviewForm.errors) {
        setReviewForm((prevReviewForm) => ({
          ...prevReviewForm,
          errors: {
            ...prevReviewForm.errors,
            [x]: false,
          }
        }));
      }
    }
    else {
      console.log("null fields");
      for (let x in reviewForm) {
        if (x !== "errors" && x != "description") {
          if (reviewForm[x] === '') {
            setReviewForm((prevReviewForm) => ({
              ...prevReviewForm,
              errors: {
                ...prevReviewForm.errors,
                [x]: true,
              }
            }));
          }
          else if (reviewForm[x] !== '') {
            setReviewForm((prevReviewForm) => ({
              ...prevReviewForm,
              errors: {
                ...prevReviewForm.errors,
                [x]: false,
              }
            }));
          }
          if (x === "email") {
            const isValidEmail = emailPattern.test(reviewForm[x]);
            if (isValidEmail) {
              setReviewForm((prevReviewForm) => ({
                ...prevReviewForm,
                errors: {
                  ...prevReviewForm.errors,
                  [x]: false,
                }
              }));
            }
            else {
              setReviewForm((prevReviewForm) => ({
                ...prevReviewForm,
                errors: {
                  ...prevReviewForm.errors,
                  [x]: true,
                }
              }));
            }
          }
        }
      }
      console.log(reviewForm);
    }
    callback();
  }
  const updateField = (event) => {
    const { name, value } = event.target;
    setReviewForm((prevReviewForm) => ({
      ...prevReviewForm,
      [name]: value
    }));
    console.log(reviewForm);
  };
  const selectImages = (files) => {
    if (reviewForm.submission_images.length < 4) {
      setImageLimitCheck(false);
      const newSubmissionImages = [...reviewForm.submission_images];
      newSubmissionImages.push(files.base64);
      setReviewForm((prevReviewForm) => ({
        ...prevReviewForm,
        submission_images: newSubmissionImages,
      }));
      setSelectedImages([...newSubmissionImages]);
    }
    else {
      setImageLimitCheck(true);
    }
  };
  const handleConcatenate = (inputValue) => {
    setReviewForm((prevReviewForm) => ({
      ...prevReviewForm,
      description: reviewForm.description + inputValue
    }));
  }
  const removeImage = (indexVal) => {
    if (selectedImages.length === 1) {
      setSelectedImages([]);
      setReviewForm((prevReviewForm) => ({
        ...prevReviewForm,
        submission_images: [],
      }));
    }
    else {
      const updatedImages = [...selectedImages];
      updatedImages.splice(indexVal, 1);
      setSelectedImages([...updatedImages]);
      setReviewForm((prevReviewForm) => ({
        ...prevReviewForm,
        submission_images: [...updatedImages],
      }));
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        Confirm
      </Button>
    </React.Fragment>
  );
  const handleEmail = (event, callback) => {
    const { value } = event.target;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailPattern.test(value);
    isValidEmail ? setReviewForm((prevReviewForm) => ({
      ...prevReviewForm,
      errors: {
        ...prevReviewForm.errors,
        email: false,
      }
    })) : setReviewForm((prevReviewForm) => ({
      ...prevReviewForm,
      errors: {
        ...prevReviewForm.errors,
        email: true,
      }
    }));
    callback(event);
  }

  useEffect(() => {
    if (renderCount === 1) {
      const script1 = document.createElement('script');
      script1.src = 'https://cdn.shopify.com/s/files/1/0736/2868/0480/t/2/assets/snare.js?v=143287665916304429601685607288';
      script1.async = true;
      document.body.appendChild(script1);
      script1.onload = () => {
        script1.defer = true;
        var bbData = window.ioGetBlackbox();
        setBlackBoxStr(bbData.blackbox);
        document.body.removeChild(script1);
      };
      setRenderCount(prevCount => prevCount + 1);
    }
    setproductList([...dataFromApi.product]);
    setReviewForm((prevReviewForm) => ({
      ...prevReviewForm,
      client: dataFromApi.client_id
    }));
    console.log("dataFromApi", dataFromApi);
  }, [])
  useEffect(() => {
    setReviewForm((prevReviewForm) => ({
      ...prevReviewForm,
      black_box: blackBoxStr
    }));
  }, [blackBoxStr])

  return (
    <>
      {/* api call snackbar */}
      {/* <div onClick={() => { setshowReview(false) }}>
        back
      </div> */}
      <Grid container className="back-button">
        <Button variant="contained" onClick={() => { setshowReview(false); setShowLeaveReviewButton(true) }} sx={{ bgcolor: "#001666", margin: "0px 90px" }} startIcon={<ArrowBackIcon />} >Back</Button>
      </Grid>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}

        >
          <Alert
            severity="success"
            action={action}
            sx={{ width: '100%' }}
            icon={<CheckCircleIcon fontSize="inherit" />}
          >
            <AlertTitle>Review Submitted</AlertTitle>
          </Alert>
        </Snackbar>
      </div>
      {/* api call snackbar */}
      <div className="review-form">
        <div className="review-form-inner">
          <form className="review-form-content">
            <div className="write-title">
              <label> First Name </label>
              <TextField
                name="first_name"
                type="text"
                id="outlined-basic"
                placeholder="Enter first name"
                variant="outlined"
                onChange={(event) => {
                  updateField(event);
                }}
              />
              {reviewForm.errors['first_name'] &&
                <div style={{ color: "red" }}>required</div>}
            </div>
            <div className="write-title">
              <label> Last Name </label>
              <TextField
                name="last_name"
                type="text"
                id="outlined-basic"
                placeholder="Enter last name"
                variant="outlined"
                onChange={(event) => {
                  updateField(event);
                }}
              />
              {reviewForm.errors['last_name'] &&
                <div style={{ color: "red" }}>required</div>}
            </div>
            <div className="write-title">
              <label> Email </label>
              <TextField
                name="email"
                type="email"
                id="outlined-basic"
                placeholder="Enter email"
                variant="outlined"
                onChange={(event) => {
                  handleEmail(event, updateField);
                }}
              />
              {reviewForm.errors['email'] &&
                <div style={{ color: "red" }}>required</div>}
            </div>
            <div className="write-review-rating">
              <h2> Rate a Product </h2>
              <Rating
                name="star"
                value={reviewForm?.rating}
                size="small"
                icon={<FilledStar fontSize="inherit" />}
                emptyIcon={<EmptyStar fontSize="inherit" />}
                onChange={(event) => {
                  setReviewForm((prevReviewForm) => ({
                    ...prevReviewForm,
                    star: event.target.value
                  }));
                }}
              />
              {reviewForm.errors['star'] &&
                <div style={{ color: "red" }}>required</div>}
            </div>
            <div className="select-product">
              <label> Choose a Product </label>
              <Autocomplete
                disablePortal
                className="select-product-dropdown"
                id="combo-box-demo"
                options={productList}
                getOptionLabel={(option) => option.title}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, selectedValue) => {
                  setReviewForm((prevReviewForm) => ({
                    ...prevReviewForm,
                    product: selectedValue.id
                  }));
                }}
              />
              {reviewForm.errors['product'] &&
                <div style={{ color: "red" }}>required</div>}
            </div>
            <div className="write-title">
              <label> Write a Title </label>
              <TextField
                name="title"
                id="outlined-basic"
                placeholder="What’s important for people to know?"
                variant="outlined"
                onChange={(event) => {
                  updateField(event);
                }}
              />
              {reviewForm.errors['title'] &&
                <div style={{ color: "red" }}>required</div>}
            </div>
            <div className="write-description">
              <label> Tell us more about your experience </label>
              <div className="pre-comments">
                <ul className="list-review">
                  <li onClick={() => handleConcatenate(' Love it')}>Love it +</li>
                  <li onClick={() => handleConcatenate(' Awesome design')}>Awesome design +</li>
                  <li onClick={() => handleConcatenate(' Perfect quality')}> Perfect quality + </li>
                  <li onClick={() => handleConcatenate(' Will continue buying')}> Will continue buying +</li>
                  <li onClick={() => handleConcatenate(' Buy it!')}> Buy it! + </li>
                </ul>
              </div>
              <div className="write-description-input">
                <TextField
                  name="description"
                  value={reviewForm.description}
                  multiline={true}
                  minRows={"10"}
                  placeholder="What made your experience great? What is the product doing well? Be honest and constructive."
                  onChange={(event) => {
                    updateField(event);
                  }}
                />
                {reviewForm.errors['description'] &&
                  <div style={{ color: "red" }}>required</div>}
              </div>
            </div>
            <div className="add-photo">
              <ReactFileReader
                sx={{ display: "flex" }}
                fileTypes={[".png", ".jpg", ".jpeg"]}
                base64={true}
                handleFiles={(event) => selectImages(event, "logo")}
              >
                <label> Upload </label>
                <AddPhoto />
              </ReactFileReader>
            </div>
            <Grid className="uploaded-images" container>
              {selectedImages.map((image, index) => (
                <div key={index} className="uploaded-image">
                  <div style={{ maxWidth: "100px", maxHeight: "100px", margin: "0px 10px", display: "flex", justifyContent: "end" }}>
                    <CloseIcon onClick={() => removeImage(index)} sx={{ cursor: "pointer" }} />
                  </div>
                  <img
                    src={image}
                    alt={`Uploaded ${image.id}`}
                    style={{ maxWidth: "100px", maxHeight: "100px", margin: "10px" }}
                  />
                </div>
              ))}
            </Grid>{imageLimitCheck &&
              <div style={{ color: 'red' }}>Cannot upload more than 4 images</div>}
            <LoadingButton className="review-submission" loading={loadingButton} color="primary" variant="contained" size="large" style={{ fontWeight: "bold" }} onClick={() => {
              apiCall(endLoader)
            }}
              sx={{
                // Customize spinner color
                "& .MuiCircularProgress-svg": {
                  color: "blue", // Change to your desired spinner color
                },
              }}
            >
              Submit Review
            </LoadingButton>
          </form>
        </div >
      </div >
    </>
  );
};
export default WriteReviewForm;
