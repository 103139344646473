import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import PromotionalBannerImage from "../../Assets/promotional-banner.png";
const PromotionalBanner = () => {
  const [categoryValue, setCategoryValue] = useState("all-categories");

  const handleChange = (event) => {
    setCategoryValue(event.target.value)
  }
  return (
    <Grid container className="category-banner promotional-banner">
      <Grid container className="category-banner-inner" sx={{backgroundImage: `url(${PromotionalBannerImage})`, height: "272px"}}>
        {/* <Grid item className="category-image">
          <img src={CategoryBannerImage} />
        </Grid> */}
        <Grid container className="text-content">
          <Grid item className="text-content-inner">
            {/* <Typography className="cat-heading" sx={{color: "#001666!important"}}>
              {" "}
              Every Review is an Experience!{" "}
            </Typography> */}
            <Typography className="cat-subheading" sx={{color: "#001666!important", fontWeight: "700!important"}}>
              {" "}
              Authentic & Honest Reviews{" "}
            </Typography>
            <Typography className="sub-banner-subtext" sx={{color: "#001666!important"}}>
              {" "}
              We post authentic reviews from real customers. Read what real people think about the products they bought.{" "}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PromotionalBanner;
