import React, { useState, useContext, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Popup = (props) => {
  const [imageToggle, setImageToggle] = useState(false)
  const [replyToggle, setReplyToggle] = useState(false)
  const [fullReviewToggle, setFullReviewToggle] = useState(false)
  useEffect(() => {
    console.log("props.popupToggle", props.popupToggle)
    setImageToggle(props.popupToggle.media)
    setReplyToggle(props.popupToggle.reply)
    setFullReviewToggle(props.popupToggle.full_review)
  }, [props.image]);
  const handleImagePopup = () => {
    setImageToggle(false)
    props.popupToggle.media = false;
    props.setPopupToggle({ ...props.popupToggle })
  };
  const handleReplyPopup = () => {
    setReplyToggle(false)
    props.popupToggle.reply = false;
    props.setPopupToggle({ ...props.popupToggle })
  }
  const handlefullReviewPopup = () => {
    setFullReviewToggle(false)
    props.popupToggle.full_review = false;
    props.setPopupToggle({ ...props.popupToggle })
  }
  return (
    <>
      <Dialog open={imageToggle} onClose={handleImagePopup}>
        <DialogContent className="image-content">
          <CloseIcon onClick={handleImagePopup} />
          <img src={props.mediaImage.src} alt="Image" />
        </DialogContent>
      </Dialog>
      <Dialog open={replyToggle} onClose={handleReplyPopup} className="reply-content">
        <DialogContent>
          {/* <CloseIcon onClick={handleImagePopup} /> */}
          <p className="answer"> {props.replyContent.answer} </p>
          <div className="replied-by-container">
            <p className="replied-by"> Replied By  <span> {props.replyContent.brand_name}</span></p>
            <p className="created-at"> {props.replyContent.created} </p>
          </div>
        </DialogContent>
        <DialogActions className="popup-footer">
          <Button onClick={handleReplyPopup}>
            close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={fullReviewToggle} onClose={handleReplyPopup} className="reply-content">
        <DialogContent>
          {/* <CloseIcon onClick={handleImagePopup} /> */}
          <p className="answer"> {props.fullReviewContent.review} </p>
          <div className="replied-by-container">
            <p className="replied-by"> Reviewed by:  <span> {props.fullReviewContent.first_name} {props.fullReviewContent.last_name}</span></p>
            <p className="created-at"> {props.fullReviewContent.created} </p>
          </div>
        </DialogContent>
        <DialogActions className="popup-footer">
          <Button onClick={handlefullReviewPopup}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;