import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { handleizeString } from "../../utils/utils.js";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import { ReactComponent as EmptyStar } from "../../Assets/empty-star.svg";
import { ReactComponent as Verify } from "../../Assets/verify.svg";
import BrandBanner from "../../Assets/pattern-outline.png";
import Rating from "@mui/material/Rating";
const BrandHeader = () => {
  const [totalRecords, setTotalRecords] = useState(20);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [brandDetail, setBrandDetail] = useState({
    title: "Baby Brezza",
    image:
      "https://cdn.shopify.com/s/files/1/0776/2165/7883/files/Ellipse_9_1.png?v=1690026130",
    total_reviews: 20666,
    avg_rating: 4.6,
    label: "Most Relevant",
    social_link: { facebook: "facebook.com", instagram: "instagram.com" },
  });

  return (
    <>
      <div className="category-list" >
        <div className="category-list-inner">
          <div className="category-list-bottom brand-banner">
            <div className="brand-grid-item">
              <div className="brand-logo">
                <img src={brandDetail.image} alt={brandDetail.title} />
              </div>
              <div className="brand-content">
                <h2> {brandDetail.title} </h2>
                <div className="review-rating">
                  <Rating
                    name="read-only"
                    value={Math.round(brandDetail.avg_rating)}
                    size="large"
                    icon={<FilledStar fontSize="inherit" />}
                    emptyIcon={<EmptyStar fontSize="inherit" />}
                    readOnly
                  />
                  <p>{brandDetail.avg_rating.toFixed(1)}</p>
                  <p> {brandDetail.total_reviews} Reviews </p>
                </div>
                <div className="verified-brand">
                  <p> Verified Company</p>
                  <Verify />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandHeader;
