import React, { useState, useEffect } from "react";

export default function Privacy() {
  useEffect(() => {
    window.scroll({
      top: 0
    });
  });
  return (
    <>
      <div className="privacy-policy">
        <div className="wrapper privacy-content">
          <h1>Privacy Policy</h1>
          <p>Last Updated: November 11, 2021</p>
          <p>
            We at Omni Boost (“Omni Boost”) know you care about how your personal information is used and shared, and we take your privacy seriously.
            <span>By using our website (http://www.omnibrandboost.com) or by using any of our social marketing services (the “Services”), you acknowledge that you understand the practices and policies outlined in these Privacy Policies.</span><br />
            For Omni Boost current or potential business customers who use our website, refer to our <a>Website Privacy Policy</a> <br />
            For consumers or end-users of Omni Boost business customers, refer to our <a>Product-Platform Privacy Policy</a>
          </p>
          <div className="text">
            <h2>Omni Boost Website Privacy Policy</h2>
            <ul className="text1">
              <li>1.	Information we collect through this website</li>
              <li>2.	How we use and share collected information</li>
              <li>3.	Your choices with our personal information uses</li>
              <li>4.	Security</li>
              <li>5.	Changes to this policy</li>
              <li>6.	Contact</li>
            </ul>
          </div>
          <h2>1. Information we collect through this website</h2>
          <p><span>Omni Boost does not intend for this website or any of the Services to collect personal information (or ‘personal data’ for EU visitors) from children under the age of 16.</span>
            If you are under 16, please do not attempt to register for the Services or send any personal information about yourself to us. If you believe that a child under 16 may have provided us with personal information, please contact us via email at <a href="">support@omnibrandboost.com </a></p>
          <p><span>We collect personal information when you directly give it to us.</span>
            We offer multiple ways in which you may contact us or send us your information, including when you install our app through Shopify, register for an account, send us emails, fill out forms, and occasionally through surveys. The information we may collect from you through these processes is:</p>
          <ul className="text2">
            <li>•   Full name</li>
            <li>•	Email address</li>
            <li>•	Phone number</li>
            <li>•	Postal address</li>
            <li>•	Business information including professional title</li>
          </ul>
          <p>On occasion, we may also ask you to provide us with information about another person and their contact information, such as with other potential business users of our Services, billing contacts or others. In such an instance, we will treat the other person's information the same as yours and in accordance with this policy.</p>
          <p><span>We automatically collect information about you when you interact with our website, app, emails, or on social media. </span>
            Like most websites and apps, we use tools to measure your engagement activities, which may include the use of pixel tags and cookies. Finally, we send email updates and offers, and may collect information about your interaction with our emails. Specifically, we collect the following pieces of information through these interactions:</p>
          <ul className="text3">
            <li>•	Website visit analytics such as referral link clicks, pages viewed, and time spent on site</li>
            <li>•	IP addresses and general geographic location derived from IP addresses</li>
            <li>•	Registered Account IDs</li>
            <li>•	Cookie IDs</li>
            <li>•	Mobile Advertising IDs</li>
            <li>•	Email opens and click-throughs that will be associated with an email address</li>
          </ul>
          <h2>2. How we use and share collected information</h2>
          <p>We use personal information to:</p>
          <ul className="text4">
            <li>•	Provide you with our services, for billing purposes.</li>
            <li>•	To improve our products and features.</li>
            <li>•	Communicate with you through email, including product and feature promotions.</li>
            <li>•	Personalize our website.</li>
            <li>•	Incorporate your feedback, testimonials or reviews into our content, products or Services. If we are interested in using any content associated with your personal information for publicity purposes, we will request your permission in advance.</li>
          </ul>
          <p>We work with business partners who provide referrals and integrate with our Services. In some cases, these partners may share personal information with us, and receive some analytics around your integration with our Services. <span>
            We are not responsible for the privacy practices of these partners when you use them in conjunction with Omni Boost.
          </span>Partners include:</p>
          <ul>
            <li>•	Shopify</li>
            <li>•	Klaviyo</li>
          </ul>
          <p>We contract with service providers who use your personal information exclusively to support Omni Boost, and include:</p>
          <ul>
            <li>•	Customer communications through Hubspot and Intercom</li>
            <li>•	Web and app analytics through Mixpanel and Google Analytics</li>
            <li>•	App and software hosting through Amazon AWS</li>
            <li>•	Online advertising through Facebook and others</li>
            <li>•	Survey tools</li>
            <li>•	Website creation and hosting</li>
          </ul>
          <p>We will not sell, rent or share your personal information with third parties for their own uses without your express consent. However, there are occasions we may share personal information for a designated business purpose, including:</p>
          <ul>
            <li>•	Through the use of proprietary links, pixel tags and cookies served by marketing partners, social media, or interest-based advertising services that may use collected information from our Services to assist Omni Boost but also to improve their products and services. Please see your choices below to opt-out of these potential uses.</li>
            <li>•	With any other divisions, subsidiaries or corporate affiliates of Omni Boost</li>
            <li>•	If we are compelled by law to do so, such as through a subpoena or court order.</li>
            <li>•	Online advertising through Facebook and others•	If it is required as part of a business transaction where Omni Boost or any subsidiary is acquired.</li>
          </ul>
          <p>We may share aggregated information that does not identify any personal information, such as the number of website or app visits, referring partner metrics, or number of account registrations.</p>
          <h2>3. Your choices with our personal information uses</h2>
          <p>If you are a business customer, you may contact us at any time and request the following choices with our use of your personal information:</p>
          <ul>
            <li>•	<span>Access.</span> We can provide you with the personal information that we maintain about you.</li>
            <li>•	<span>Modification.</span>We will correct any mistakes with your personal information.</li>
            <li>•	<span>Opt-Out.</span>You may request that we cease using your information for marketing or other purposes that are not required to provide the services you’ve requested. You may also choose to opt-out through the following mechanisms:
              <ul>
                <li>1.	If you have received a commercial email from us, you will have the option to opt-out or unsubscribe in the email itself.</li>
                <li>2.	Social and interest-based advertising partners, notably Facebook. You may also choose to opt-out of these and other potential partners through this industry website or by emailing us.</li>
              </ul>
            </li>
            <li>•	<span>Deletion.</span>You can request that we delete any personal information about you, unless we are required by contract or law to retain it.</li>
          </ul>
          <p>Your br/owser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at this time.</p>
          <p>For European visitors, we process personal data under the following legal basis:</p>
          <ul>
            <li>•	Contract fulfillment: We are a data processor acting upon the express instructions of our customers.</li>
            <li>•	Legitimate interest: We use personal data to send marketing and other communications to our business customers. We may also use information about our business customers’ use of our products and services to improve our products and services.</li>
          </ul>
          <p>Omni Boost is operated in Canada and will process personal information exclusively in Canada. If you are visiting this website from outside Canada, then by providing us with your personal information you are consenting to the transfer of that information to Canada. For business customers, we rely on model contracts approved by the European Commission, or the U.S.-EU Privacy Shield Framework when we transfer personal data to be processed by our service providers or partners in the U.S.</p>
          <p>European visitors who wish to exercise their data subject rights may contact us, or file a complaint, by emailing us at <a href="#">support@omnibrandboost.com</a> Alternatively, you may lodge a complaint with your local Data Protection Authority.</p>
          <h2>4. Security</h2>
          <p>We conduct due diligence on all our personal information service providers and require them to have adequate security protections in place. If Omni Boost processes your personal information on our own systems or servers, we will also put in place mechanisms to prevent unauthorized access or disclosures. We endeavor to protect the privacy of your account and other personal information we hold in our records, but unfortunately, we cannot guarantee complete security.
            <br />
            We will retain your personal information as long as we consider it relevant in order to maintain a relationship with you, or as required by law. As noted above, you can request we delete your information at any time.
          </p>
          <h2>5. Changes to this policy</h2>
          <p>If we change this policy, we will post an update on our home page. If the change involves something substantially different from our current policy, we will post the update in advance of the change taking place and send an email to everyone subscribed to our email updates.</p>
          <h2>6. Contact</h2>
          <p><span>You may reach us at support@omnibrandboost.com or</span><br />
            6265 Cote de liesse<br />
            Saint Laurent, QC H4W 2Y9
          </p>
          <h2>Omni Boost Product-Platform Privacy Policy</h2>
          <ul className="text1">
            <li>1.	Information we collect through our Products</li>
            <li>2.	How our Service uses and shares collected information</li>
            <li>3.	Your choices with our Product personal information uses</li>
            <li>4.	California Consumer Disclosure</li>
            <li>5.	Product Security</li>
            <li>6.	Changes to this policy</li>
            <li>7.	Contact</li>
          </ul>
          <h2 className="underline-heading">1. Information we collect through our Products</h2>
          <p><span>We collect personal information from end-users of Omni Boost Customers, including:</span></p>
          <ul>
            <li>•	When an end-user provides personal information to a Omni Boost messaging bot which may include their Name, Gender, their profile photo or any other information which they write in text or otherwise engaging with the bot.</li>
            <li>•	We may also receive personal information of end-users from our partners or service providers such as Shopify and Facebook.</li>
            <li>•	We use cookies, mobile advertising identifiers and other technologies to track individual end-users of our Products.</li>
          </ul>
          <h2 className="underline-heading">2. How our Service uses and shares collected information</h2>
          <p>We use collected information to provide our social and other marketing services, including:</p>
          <ul>
            <li>•	Facebook Messenger bots</li>
            <li>•	Website engagement tools</li>
            <li>•	Partner integration tools</li>
            <li>•	Customer and end-user support</li>
          </ul>
          <p>We share information with the following partners and service providers</p>
          <ul>
            <li>•	Facebook</li>
            <li>•	Google Analytics</li>
            <li>•	Amazon AWS</li>
            <li>•	Yotpo</li>
            <li>•	BazaarVoice</li>
          </ul>
          <h2 className="underline-heading">3. Your choices with our personal information uses</h2>
          <p>As Omni Boost is a service provider on behalf of our business customers, any requests to access, modify, object or delete your personal should be directed to the business customer with whom you wish to make such a request. However, you may customize your br/owser or mobile operating system privacy settings to limit our use of cookies or mobile device IDs, or manage your privacy with Facebook Messenger through their privacy settings.</p>
          <p>For European visitors, you may exercise your data subject rights by emailing us at <a href="#">support@omnibrandboost.com</a> with the specific rights request and identified Omni Boost business customer. We will direct your request to the business customer for an authorized response.</p>
          <p>For California consumers, we are considered a service provider under the CCPA and disclose your rights below.</p>
          <div className="table-heading">
            <h2>4. California Consumer Disclosure</h2>
          </div>
          <table className="table plan-table-container">

            <tbody className="plan-table-body">
              <tr>
                <td>Definitions</td>
                <td>The terms “Business Purpose(s)”, “Personal Information”, “Sell” (or “Sale”), “Service Provider(s)” and “Third Parties” have the meanings given in the California Consumer Privacy Act of 2018 (CCPA). These term references and disclosures are limited to this section of the Privacy Policy and designated exclusively for California Consumers.</td>
              </tr>
              <tr>
                <td>Categories of Personal Information</td>
                <td>Direct contact information including names, email and postal addresses, and phone numbers.
                  Unique user or device Identifiers such as Account IDs, Unique Referral URLs, Cookies, Mobile Ad IDs (IDFAs for iOS and Android Ad IDs), Timestamps, User Agent Strings, IP addresses and general location data that is no more specific than a postal code.
                </td>
              </tr>
              <tr>
                <td>Purposes for collecting Personal Information</td>
                <td>We collect personal information to provide social marketing products and services.</td>
              </tr>
              <tr>
                <td>Categories of sources from which Personal Information is collected</td>
                <td>Websites, social marketing services, technology platforms.</td>
              </tr>
              <tr>
                <td>Purposes for use of collected Information</td>
                <td>We use collected personal information to provide social interaction services, track website activity, personalize ads, or improve our products.</td>
              </tr>
              <tr>
                <td>Categories of Personal Information we sell</td>
                <td>We do not sell personal information.</td>
              </tr>
              <tr>
                <td>Purposes for selling personal information</td>
                <td>We do not sell personal information.</td>
              </tr>
              <tr>
                <td>Categories of Third Parties to whom Personal Information is sold</td>
                <td>We do not sell personal information.</td>
              </tr>
              <tr>
                <td>Categories of third parties to whom information was shared for a business purpose</td>
                <td>Social marketing services, ecommerce services, communications services.</td>
              </tr>
              <tr>
                <td>Access or Delete Your Personal Information</td>
                <td>Email us at privacy@Omni Boost.com with the name of the business customer and your specific request. We will direct your request to the business customer for an authorized response.</td>
              </tr>
              <tr>
                <td>Opt Out of Sales or Exercise Your “Do Not Sell My Personal Information” Right Under CCPA</td>
                <td>We do not sell your personal information.</td>
              </tr>
            </tbody>
          </table>

          <h2>5. Product Security</h2>
          <p>We conduct due diligence on all our personal information service providers, and require them to have adequate security protections in place. If Omni Boost processes your personal information on our own systems or servers, we will also put in place mechanisms to prevent unauthorized access or disclosures. We endeavor to protect the privacy of your account and other personal information we hold in our records, but unfortunately, we cannot guarantee complete security.</p>
          <p>We will retain your personal information as long as we consider it relevant in order to maintain a relationship with you, or as required by law.</p>
          <h2>6. Changes to this policy</h2>
          <p>If we change this policy, we will post an update on our home page. If the change involves something substantially different from our current policy, we will post the update in advance of the change taking place and send an email to everyone subscribed to our email updates.</p>
          <h2>7. Contact</h2>
          <p><span>You may reach us at support@omnibrandboost.com or</span><br />
            6265 Cote de liesse<br />
            Saint Laurent, QC H4W 2Y9
          </p>
        </div>
      </div>
    </>
  )
}
