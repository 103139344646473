import React, { useEffect, useState } from "react";
import CategoryBanner from "./category-banner";
import TopCategories from "./top-categories";
import LatestReviews from "./latest-reviews";
import PromotionalBanner from "./promotional-banner";
import "./index.css";
import HonestarApis from "../../Apis/Api.js";
import Skeleton from "@mui/material/Skeleton";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [latestReviews, setLatestReviews] = useState(null);
  useEffect(() => {
    getAllCategoriesAndReviews();
  }, []);
  const getAllCategoriesAndReviews = () => {
    setIsLoading(true);
    HonestarApis.getAllCategoriesAndReviews()
      .then((data) => {
        setIsLoading(false);
        setCategories(data.category);
        setLatestReviews(data.review);
      })
      .catch((error) => setIsLoading(false));
  };
  return (
    <>
      {!isLoading ? <CategoryBanner categories={categories} /> : null}
      {!isLoading ? <TopCategories categories={categories} /> :
        <div className="category-bottom">
          <div className="category-bottom-inner">
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
            <Skeleton className="skeleton-item" />
          </div>
        </div>
      }
      {latestReviews ? <LatestReviews latestReviews={latestReviews} /> : null}
      <PromotionalBanner />
    </>
  );
};

export default Home;
