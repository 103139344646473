import Axios from "axios";
import { backendUrl } from "../utils/utils.js";

let categoriesAndReviewsURL = "/honestars/category";
let categoryBrandsListURL = "/honestars/categoryBrandList";
let brandDetailURL = "/honestars/brandDetail";
let reviewURL = "/honestars/leaveReview";
let searchBrandURL = "/honestars/search";
let reviewsURL = "/honestars/ReviewList"
let leaveReviewURL = "/honestars/leaveReview";


const HonestarApis = {
    getAllCategoriesAndReviews() {
        return Axios({
            url: backendUrl + categoriesAndReviewsURL,
            method: "get",
            headers: {
                "content-type": "application/json",
            },
        }).then((response) => response.data);
    },
    getCategoryBrandsList(params) {
        return Axios({
            url: backendUrl + categoryBrandsListURL + params,
            method: "get",
            headers: {
                "content-type": "application/json",
            },
        }).then((response) => response.data);
    },
    getCategoryBrandDetail(params) {
        return Axios({
            url: backendUrl + brandDetailURL + params,
            method: "get",
            headers: {
                "content-type": "application/json",
            },
        }).then((response) => response.data);
    },
    getBrandReviews(params) {
      return Axios({
          url: backendUrl + reviewsURL + params,
          method: "get",
          headers: {
              "content-type": "application/json",
          },
      }).then((response) => response.data);
  },
    leaveReview(data) {
        return Axios({
            url: backendUrl + leaveReviewURL,
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            data: data,
        }).then((response) => response.data);
    },
   
    searchBrand(params) {
        return Axios({
            url: backendUrl + searchBrandURL + params,
            method: "get",
            headers: {
                "content-type": "application/json",
            },
        }).then((response) => response.data);
    },
}
export default HonestarApis;