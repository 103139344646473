import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import { ReactComponent as EmptyStar } from "../../Assets/empty-star.svg";
import Rating from "@mui/material/Rating";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Skeleton from "@mui/material/Skeleton";
import NoImage from "../../Assets/no-image.svg";
import Popup from "../Reuseable-Components/popup.js"
import Tooltip from '@mui/material/Tooltip';

const LatestReviews = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewList, setReviewList] = useState();
  const [popupToggle, setPopupToggle] = useState({
    media: false,
    reply: false,
    full_review: false
  })
  const [mediaImage, setMediaImage] = useState({})
  const [replyContent, setReplyContent] = useState({})
  const [fullReviewContent, setFullReviewContent] = useState({})
  // {
  //   name: "Mei s",
  //   date: "12/06/23",
  //   product_title: "Fibertech",
  //   star: 4.2,
  //   review_title: "“I Love This Luggage”",
  //   review_description:
  //     "I have not use it but it seem so good and good quality. Love the color.",
  //   image:
  //     "https://cdn.shopify.com/s/files/1/2197/3237/products/fibertech_gold_28.jpg",
  // },
  useEffect(() => {
    setReviewList(props.latestReviews);
    setIsLoading(false)
  }, []);

  const handleMedia = (key, image) => {
    console.log(image)
    Object.keys(popupToggle).forEach((v) => (popupToggle[v] = false));
    setPopupToggle({ ...popupToggle, [key]: true });
    mediaImage.src = image;
    setMediaImage(mediaImage)
    console.log("popupToggle", popupToggle)
    console.log("mediaImage", mediaImage)
  }

  const handleReply = (key, answer, created, clientName) => {
    console.log(key)
    Object.keys(popupToggle).forEach((v) => (popupToggle[v] = false));
    setPopupToggle({ ...popupToggle, [key]: true });
    replyContent.answer = answer;
    replyContent.created = created;
    replyContent.brand_name = clientName;
    setReplyContent({ ...replyContent })
    console.log("popupToggle", popupToggle)
    console.log("replyContent", replyContent)
  }

  const handleFullReview = (key, review, created, firstName, lastName) => {
    console.log(key)
    // popupToggle.media = false;
    // popupToggle.reply = true;
    Object.keys(popupToggle).forEach((v) => (popupToggle[v] = false));
    setPopupToggle({ ...popupToggle, [key]: true });
    fullReviewContent.review = review;
    fullReviewContent.created = created;
    fullReviewContent.first_name = firstName;
    fullReviewContent.last_name = lastName;
    setFullReviewContent({ ...fullReviewContent })
    // setPopupToggle({ ...popupToggle });
    console.log("popupToggle", popupToggle)
    console.log("replyContent", replyContent)
  }

  return (
    <>
      <div className="latest-reviews">
        <div className="latest-reviews-inner">
          <div className="reviews-top">
            <h1> Latest Reviews </h1>
          </div>
          <div className="reviews-bottom">
            <div
              className={
                isLoading ? "skeleton-loading" : "reviews-bottom-inner"
              }
            >
              {!isLoading ? (
                <Swiper
                  className={"review-slider-wrapper"}
                  spaceBetween={20}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  // slidesPerView={3}
                  modules={[Autoplay,Pagination]}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    400: {
                      slidesPerView: 1,
                    },
                    639: {
                      slidesPerView: 2,
                    },
                    865: {
                      slidesPerView: 3,
                    },
                    1000: {
                      slidesPerView: 3,
                    },
                    1500: {
                      slidesPerView: 3,
                    },
                    1700: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {reviewList?.map((item, key) => (
                    <SwiperSlide className="review-item" key={key}>
                      <div className="review-item-inner">
                        <div className="review-item-top">
                          <div className="product-image">
                            <img src={item.product.image ? item.product.image : NoImage} alt={item.product.title} />
                          </div>
                          <div className="product-title-and-rating">
                            <h3 className="product-title">
                              {" "}
                              {item.product.title}{" "}
                            </h3>
                            <div className="review-rating">
                              <Rating
                                name="read-only"
                                value={Math.round(item.stars)}
                                size="small"
                                icon={<FilledStar fontSize="inherit" />}
                                emptyIcon={<EmptyStar fontSize="inherit" />}
                                readOnly
                              />
                              <p>{item.stars.toFixed(1)}</p>
                            </div>
                          </div>
                        </div>
                        <div className="review-item-center">
                      <p className="review-title"> {item.title}</p>
                      <p className="review-description" onClick={(e) => {
                        handleFullReview("full_review", item.description, item.created_at, item.customer.first_name, item.customer.last_name)
                      }}>
                        {" "}
                        <Tooltip title={"click to read full review"} placement="top">
                          {item.description}{" "}
                        </Tooltip>
                      </p>
                      <div className="review-media">
                        <div className="review-media-inner">
                          {item?.submission_images?.map((item1, key1) => (
                            <div className="review-media-item" key={key1} onClick={(e) => { handleMedia("media", item1.image) }}>
                              <img src={item1.image} alt={item1.title} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                        <div className="review-item-bottom">
                          <div className="reviewed-by">
                            <p className="reviewed-label">
                              {" "}
                              Reviewed by: <span> {item.customer.first_name} {item.customer.last_name} </span>
                            </p>
                            <span className="review-date"> {item.created_at} </span>
                          </div>
                          {
                            item?.answer ?
                              <div className="view-reply">
                                <span name="reply" onClick={(e) => { handleReply("reply", item.answer, item.created_at, item.client_name) }}> View Reply </span>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <>
                  <Skeleton className="skeleton-item" />
                  <Skeleton className="skeleton-item" />
                  <Skeleton className="skeleton-item" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {popupToggle.media || popupToggle.reply || popupToggle.full_review ?
        <Popup popupToggle={popupToggle} setPopupToggle={setPopupToggle} mediaImage={mediaImage} replyContent={replyContent} fullReviewContent={fullReviewContent} />
        : null}
    </>
  );
};
export default LatestReviews;
