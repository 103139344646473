import React, { useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  FormControlLabel,
  DialogActions,
  CircularProgress,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Switch,
} from "@mui/material";

const ContactForm = (props) => {
  const [dialogLoading, setDialogLoading] = useState(false);
  const [contact, setContact] = useState({});

  const updateFields = (key, value) => {
    setContact({ ...contact, [key]: value });
    console.log("contact", contact)
  }
  const sendForm = () => { };
  return (
    <Dialog
      open={props.contactDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      onClose={() => props.setContactDialog(false)}
    >
      <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: "#001666" }}>
        <Typography className="dialog-title" sx={{ color: "#FFFFFF" }}>
          Contact Us
        </Typography>
      </DialogTitle>
      {dialogLoading ? (
        <div className="tc">
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <form className="contact-form" onSubmit={sendForm()}>
            <DialogContent sx={{ paddingTop: "30px !important" }}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ minWidth: "100%" }}
                    sm={{ width: "270px" }}
                    required
                    size="small"
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    name="first_name"
                    value={contact?.first_name}
                    onChange={(e) => updateFields(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ minWidth: "100%" }}
                    sm={{ width: "270px" }}
                    required
                    size="small"
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    name="last_name"
                    value={contact?.last_name}
                    onChange={(e) => updateFields(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    size="small"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={contact?.email}
                    sx={{ minWidth: "100%" }}
                    onChange={(e) => updateFields(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    size="small"
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    name="description"
                    multiline={true}
                    minRows={5}
                    value={contact?.description}
                    sx={{ minWidth: "100%" }}
                    onChange={(e) => updateFields(e.target.name, e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => props.setContactDialog(false)}
                color="primary"
              >
                {" "}
                Close{" "}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                autoFocus
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
};
export default ContactForm;
