import React, { useState, useEffect } from "react";
import BrandHeader from "./brand-header.js";
import Filters from "./brand-filter.js";
import BrandList from "./brand-list.js";
import HonestarApis from "../../Apis/Api";
import WriteReviewForm from "../WriteReview/write-review-form.js";
import "./index.css";
const Brand = () => {
  const [brandDetailData, setBrandDetailData] = useState({});
  const [showLeaveReviewButton, setShowLeaveReviewButton] = useState(true);
  const [showReview, setshowReview] = useState(false);
  const [renderHeader, setRenderHeader] = useState(false);
  const [renderBrandList, setRenderBrandList] = useState(false);
  const [brandReviewsData, setBrandReviewsData] = useState({});
  const [totalReviews, setTotalReviews] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [productId, setProductId] = useState(null);
  const [sortValue, setSortValue] = useState("most_relevant");
  const [brandId, setBrandID] = useState(null)
  const [prevCursor, setPrevCursor] = useState(null)
  const [nextCursor, setNextCursor] = useState(null)
  const [currentCursor, setCurrentCursor] = useState(0)
  const [cursor, setCursor] = useState(false)
  const [cursorValue, setCursorValue] = useState(null)
  const [prevCursorArray, setPrevCursorArray] = useState([null])


  useEffect(() => {
    //getting ID for the API 
    const url = window.location.href;
    const searchParams = new URLSearchParams(new URL(url).search);
    let id = searchParams.get("id");
    getCategoryBrandDetail(id)
  }, [])
  const getBrandReviews = (id) => {
    let param = `?brand_id=${id}&sort=${sortValue}`;
    if (rating !== null && rating !== undefined) {
      param += `&rating=${rating}`
    }
    console.log(productId)
    if (productId !== null && productId !== undefined) {
      param += `&product=${productId}`
    }
    console.log("cursorValue", cursorValue)
    if(cursorValue) {
      param+= `&cursor=${cursorValue}`
    }
    console.log("prevCursorArray", prevCursorArray)
    console.log("currentCursor", currentCursor)
    HonestarApis.getBrandReviews(param).then((data) => {
      // setIsLoading(false);
      setBrandReviewsData(data);
      setRenderBrandList(true);
      let prevCursorString = data.previous;
      let nextCursorString = data.next;
      if (prevCursorString) {
        let url = new URL(prevCursorString);
        let prevCursorValue = url.searchParams.get("cursor");
        setPrevCursor(decodeURIComponent(prevCursorValue))
      }
      else {
        setPrevCursor(null)
      }
      if (nextCursorString) {
        let url = new URL(nextCursorString);
        let nextCursorValue = url.searchParams.get("cursor");
        setNextCursor(decodeURIComponent(nextCursorValue))
      }
      else {
        setNextCursor(null)
      }
      setCursorValue(null)
        // window.scroll({
        //   top: 0
        // });
    })
      .catch((error) => {
        // setIsLoading(false)
      });
  }
  useEffect(() => {
    const url = window.location.href;
    const searchParams = new URLSearchParams(new URL(url).search);
    let id = searchParams.get("id");
    getBrandReviews(id);
  }, [rating, productId, sortValue, cursor])

  const getCategoryBrandDetail = (id) => {
    let param = `?brand_id=${id}`
    HonestarApis.getCategoryBrandDetail(param)
      .then((data) => {
        // setIsLoading(false);
        data.brand_detail = data.brand_detail[0];
        setBrandDetailData(data)
        setRenderHeader(true)
      })
      .catch((error) => {
        // setIsLoading(false)
      });
  }

  return (
    <>
      <div className="brand-page">
        {renderHeader ?
          <BrandHeader averageRating={averageRating} totalReviews={totalReviews} dataFromApi={brandDetailData} setshowReview={setshowReview} showReview={showReview} showLeaveReviewButton={showLeaveReviewButton} setShowLeaveReviewButton={setShowLeaveReviewButton} />
          : null}
        {!showReview ? (
          <div className="brand-main">
            <div className="brand-main-inner">
              {renderBrandList ?
                <>
                  <BrandList currentCursor={currentCursor} setCurrentCursor={setCurrentCursor} prevCursorArray={prevCursorArray} setPrevCursorArray={setPrevCursorArray} setSortValue={setSortValue} sortValue={sortValue} cursor={cursor} setCursor={setCursor} nextCursor={nextCursor} prevCursor={prevCursor} setCursorValue= {setCursorValue} averageRating={averageRating} totalReviews={totalReviews} dataFromApi={brandDetailData} dataFromApiWithRating={brandReviewsData} />
                  <Filters dataFromApi={brandDetailData} setRating={setRating} setProductId={setProductId} />
                </>
                : null}
            </div>
          </div>) : (
          <WriteReviewForm dataFromApi={brandDetailData} showReview={showReview} setshowReview={setshowReview} setShowLeaveReviewButton={setShowLeaveReviewButton} />)}
      </div>
    </>
  );
};

export default Brand;
