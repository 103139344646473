import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { handleizeString } from "../../utils/utils.js";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import { ReactComponent as EmptyStar } from "../../Assets/empty-star.svg";
import { ReactComponent as Verify } from "../../Assets/verify.svg";
import { ReactComponent as LeaveReview } from "../../Assets/review-button.svg";
import BrandBanner from "../../Assets/pattern-outline.png";
import Rating from "@mui/material/Rating";
import NoImage from "../../Assets/no-image.svg";
import { brandShop } from "../../utils/data.js"
const BrandHeader = (props) => {
  const [brandLink, setBrandLink] = useState(null);
  useEffect(() => {
    console.log("props.dataFromApi.brand_detail", props.dataFromApi?.brand_detail?.reviews_count)
    console.log(brandShop)
    handleBrandLink()
  }, [])

  const handleBrandLink = () => {
    // Iterate through the brands
    for (const brand of brandShop) {
      if (props.dataFromApi?.client_name.includes(brand.title)) {
        setBrandLink(brand.path);
        break; // Stop searching after the first match
      }
    }
  }

  return (
    <>
      <div className="category-list">
        <div className="category-list-inner">
          <div className="category-list-bottom brand-banner">
            <div className="brand-grid-item">
              <div className="brand-logo">
                <a target="_blank" rel="noreferrer" href={props.dataFromApi?.brand_detail?.website_url ? props.dataFromApi?.brand_detail?.website_url : "#"}>
                  <img src={props.dataFromApi?.brand_detail?.logo ? props.dataFromApi?.brand_detail?.logo : NoImage} alt={props.dataFromApi?.brand_detail?.name} />
                </a>
              </div>
              <div className="brand-content">
                <a target="_blank" rel="noreferrer" href={props.dataFromApi?.brand_detail?.website_url ? props.dataFromApi?.brand_detail?.website_url : "#"}>
                  <h2> {props.dataFromApi.client_name} </h2>
                </a>
                <div className="review-rating">
                  <Rating
                    name="read-only"
                    value={props.dataFromApi?.brand_detail?.reviews_avg}
                    size="large"
                    icon={<FilledStar fontSize="inherit" />}
                    emptyIcon={<EmptyStar fontSize="inherit" />}
                    readOnly
                  />
                  <p>{props.dataFromApi?.brand_detail?.reviews_avg?.toFixed(1)}</p>
                  <p> {props.dataFromApi?.brand_detail?.reviews_count} Reviews </p>
                </div>
                <div className="verified-brand">
                  <p> Verified Company</p>
                  <Verify />
                </div>
              </div>
              <div className="leave-review" style={{ cursor: "pointer" }}>
                <div className="shop-now">
                  <a href={brandLink} target="_blank"> Shop Now </a>
                </div>
                {props.showLeaveReviewButton &&
                  <LeaveReview onClick={() => { props.showReview ? props.setshowReview(false) : props.setshowReview(true); props.setShowLeaveReviewButton(false) }} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandHeader;
