
export const brandShop = [
  { title: "Ballard Pacific", path: "https://shopballardpacific.com/" },
  { title: "Avenlur", path: "https://avenlur.com/" },
  { title: "Franklin Sensors", path: "https://franklinsensors.com/" },
  { title: "MlilyUSA", path: "https://mlilyusa.com/" },
  { title: "Saturday Park", path: "https://saturdaypark.com/" },
  { title: "Enchante", path: "https://www.walmart.com/browse/clothing/accessories/enchante/5438_1045799_426265_6025738/YnJhbmQ6RW5jaGFudGUie" },
  { title: "Kennedy Intl", path: "https://www.walmart.com/cp/mainstays/1228441" },
  { title: "Sunny Side Up", path: "https://sunnysideupkids.com/" },
  { title: "Seam Seal", path: "https://www.walmart.com/c/brand/protectease" },
  { title: "Imaginarium", path: "https://imaginariumandco.com/" },
  { title: "Nap Queen", path: "https://www.napqueensleep.com/" },
  { title: "DH Lifelabs", path: "https://dhlifelabs.com/" },
  { title: "Concept One", path: "https://ful.com/" },
  { title: "Smile Twice", path: "https://www.smiletwice.com/" },
  { title: "Evolve Food", path: "https://evolvefoodsllc.com/" },
  { title: "Cipton", path: "https://cipton.com/" },
  { title: "Sports Dimension", path: "https://www.bodyglove.com/collections/life-jackets-vests-for-the-whole-family" },
  { title: "Surf9", path: "https://www.bodyglove.com/collections/isup-boards" },
  { title: "Rugged Shark", path: "https://ruggedshark.com/" },
  { title: "Ground Up", path: "https://www.walmart.com/brand/groundup/10001514" },
  { title: "Everstar", path: "https://www.walmart.com/c/brand/holiday-time" },
  { title: "Bulbhead", path: "https://www.bulbhead.com/" },
  { title: "Penn Plax", path: "https://www.penn-plax.com/" }
];
