import React, { useState, useEffect } from "react";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  Select,
  Option,
  MenuItem,
} from "@mui/material";
import { handleizeString } from "../../utils/utils.js";
import { history } from "../index";
const Filters = (props) => {
  const [categoryValue, setCategoryValue] = useState("all-categories");
  const [categoryList, setCategoryList] = useState(null);
  const handleChange = (event) => {
    setCategoryValue(event.target.value);
    var categoryId = categoryList.filter(function (category) {
      console.log(handleizeString(category.name) + " " + event.target.value)
      return handleizeString(category.name) === event.target.value
    });
    props.setCategoryId(categoryId[0].id)
    console.log("categoryId:", categoryId[0].id);
    console.log("categoryName:", event.target.value);
    window.history.replaceState(null, "Honestars", `/category/${event.target.value}?id=${categoryId[0].id}`)
  };
  useEffect(() => {
    setCategoryValue(props.categoryName)
    setCategoryList(props.categories);
    console.log("props.categories", props.categories)
    eventlistners()
  }, []);
  const eventlistners = () => {
    let starElements = document.querySelectorAll(".rating-grid > div");
    starElements?.forEach((item) => {
      item.addEventListener("click", (event) => {
        document.querySelector(".rating-grid > div.active")?.classList.remove("active")
        item?.classList.add("active")
      })
    })
  };
  return (
    <>
      <div className="filter">
        <div className="filter-inner">
          <div className="filter-card-1 card">
            <div className="rating">
              <h3> Rating </h3>
              <div className="rating-grid">
                <div className="rating-grid-item star-all active" onClick={() => { props.filteredBrandList(0) }}>
                  <span> Any </span>
                </div>
                <div className="rating-grid-item star-2" onClick={() => { props.filteredBrandList(2) }}>
                  <FilledStar />
                  <span> 2+ </span>
                </div>
                <div className="rating-grid-item star-3" onClick={() => { props.filteredBrandList(3) }}>
                  <FilledStar />
                  <span> 3+ </span>
                </div>
                <div className="rating-grid-item star-4" onClick={() => { props.filteredBrandList(4) }}>
                  <FilledStar />
                  <span> 4+ </span>
                </div>
                <div className="rating-grid-item star-5" onClick={() => { props.filteredBrandList(5) }}>
                  <FilledStar />
                  <span> 5 </span>
                </div>
              </div>
            </div>


          </div>
          <div className="filter-card-2 card">
            <label> Categories </label>
            {categoryList && <FormControl className="category-formcontrol filter-category-formcontrol">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Select category"
                size={"small"}
                value={categoryValue}
                onChange={(e) => { handleChange(e) }}
              >
                {categoryList?.map((item, key) => (
                  <MenuItem value={handleizeString(item.name)} key={key} id={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>}
          </div>
        </div>
      </div>
    </>
  );
};
export default Filters;
