import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-4P7V9PVR9Z';
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', 'G-4P7V9PVR9Z');

    // Clean up the script element after it's been added
    return () => {
      document.head.removeChild(script);
    };

  }, []); // Empty dependency array ensures useEffect runs only once

  return null; // This component doesn't render anything visible in the UI
};

export default GoogleTagManager;
