import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "./index.css";
import { createBrowserHistory } from "history";
import "@fontsource/poppins";
import Home from "./Home";
import Header from "./Header";
import Category from "./Category";
import Brand from "./Brand";
import Footer from "./Footer";
import WriteReview from "./WriteReview";
import Privacy from "./Privacy"
import TermsAndConditions from "./TermAndConditions";
import Gtag from "../Components/Marketing/Gtag"
export const history = createBrowserHistory();
history.listen((location, action) => {
  console.log("action", action)
  if (["PUSH"].includes(action)) {
    window.scroll({
      behavior: "smooth",
      top: 0
    });
  }
});
const AppHome = () => {
  return (
    <Router history={history}>
      <div>
        <Gtag />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:categoryName" element={<Category />} />
          <Route path="/brands/:brandName" element={<Brand />} />
          <Route
            path="/brands/:brandName/write-review"
            element={<WriteReview />}
          />
          <Route
            path="/"
            element={<WriteReview />}
          />
          <Route
            path="/privacy"
            element={<Privacy />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default AppHome;
