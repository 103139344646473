//api to call https://be.wholescale.net/honestars/categoryBrandList?category_id=3
import React, { useEffect, useState } from "react";
import HonestarApis from "../../Apis/Api.js";
import {
  Grid,
  TextField,
  Autocomplete,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import { handleizeString } from "../../utils/utils.js";
import CategoryBannerImage from "../../Assets/category-banner.jpg";
import "./index.css";


const CategoryBanner = (props) => {
  const [categoryValue, setCategoryValue] = useState("all-categories");
  const [categoryList, setCategoryList] = useState(null);
  const [brandsList, setBrandsList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    console.log("props.categories", props.categories);
    let sortedArray = props.categories.slice().sort((a, b) => a.name.localeCompare(b.name));
    setCategoryList(sortedArray);
  }, []);


  const handleChange = (event) => {
    setCategoryValue(event.target.value);
    const catId = categoryList.find((option) => handleizeString(option.name) === event.target.value);
    setLink(`/category/${event.target.value}?id=${catId.id}`);
  };

  const handleOptionClick = (option) => {
    setSelectedBrand(option);
    if (option !== null) {
      let brandName = handleizeString(option.name);
      setLink(`/brands/${brandName}?id=${option.id}`);
    }
    else {
      setLink(null)
    }
  };


  const redirectToLink = () => {
    if (link) {
      window.location.replace(link);
    }
  }

  const getCategoryBrands = (id) => {
    setSelectedCategory(id)
    let params = `?category_id=${id}`;
    HonestarApis.getCategoryBrandsList(params).then((data) => {
      setBrandsList(data)
    });
  }

  const searchBrand = (value) => {
    if (selectedCategory === null) {
      const params = `?key=${value}`
      HonestarApis.searchBrand(params).then((data) => {
        setBrandsList(data);
      });
    }
  }


  return (
    <Grid container className="category-banner">
      <Grid
        container
        className="category-banner-inner"
        sx={{ backgroundImage: `url(${CategoryBannerImage})`, height: "272px" }}
      >
        <Grid container className="text-content">
          <Grid item className="text-content-inner">
            <Typography className="cat-heading">
              {" "}
              Shop Smarter{" "}
            </Typography>
            <Typography className="cat-subheading">
              {" "}
              <span> Find the best products on the market. </span>
            </Typography>
          </Grid>
          <Grid container className="search-cat-container">
            <Grid item className="category-input" sx={{ height: "inherit" }}>
              <Autocomplete
                mt={'-10px'}
                disablePortal
                id="combo-box-demo"
                value={selectedBrand || null}
                options={brandsList}
                sx={{ width: 300, marginTop: '-8px' }}
                renderInput={(params) =>
                  <TextField {...params} placeholder="Brand" onChange={e => searchBrand(e.target.value)} />
                }
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option, index) => (
                  <li {...props}>{option.name}</li>
                )}
                onChange={(event, newValue) => { handleOptionClick(newValue) }}
              />
            </Grid>

            <Grid item className="category-dropdown">
              <FormControl className="category-formcontrol">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryValue}
                  onChange={handleChange}
                >
                  <MenuItem value={"all-categories"}>Select Category</MenuItem>
                  {categoryList?.map((item, key) => (
                    <MenuItem value={handleizeString(item.name)} data-cat-id={item.id} key={key} onClick={() => { getCategoryBrands(item.id) }}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className="search-button">
              <Button onClick={() => { redirectToLink() }}>Search</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CategoryBanner;
