import React, { useEffect, useState } from "react";
import ToysIcon from "@mui/icons-material/Toys";
import SportsIcon from "@mui/icons-material/Sports";
import PetsIcon from '@mui/icons-material/Pets';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import YardIcon from '@mui/icons-material/Yard';
import HomeIcon from '@mui/icons-material/Home';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LuggageIcon from '@mui/icons-material/Luggage';
import { ReactComponent as Foootwear } from "../../Assets/shoes.svg";
import ChairIcon from '@mui/icons-material/Chair';
const CategoryIcon = (props) => {
  return (
    <>
      {props.Icon === "toys" ? (
        <ToysIcon />
      ) : props.Icon === "sports-outdoors" ? (
        <SportsIcon />
      ) : props.Icon === "pets" ? (
        <PetsIcon />
      ): props.Icon === "personal-care" ? (
        <SelfImprovementIcon />
      ) : props.Icon === "patio-garden" ? (
        <YardIcon />
      ) : props.Icon === "home" ? (
        <HomeIcon />
      ): props.Icon === "electronics" ? (
        <ElectricBoltIcon />
      ): props.Icon === "clothing" ? (
        <CheckroomIcon />
      ): props.Icon === "auto-tires" ? (
        <TimeToLeaveIcon />
      ) : props.Icon === "food" ? (
        <LocalDiningIcon />
      ) : props.Icon === "luggage-accessories" ? (
        <LuggageIcon />
      ) : props.Icon === "footwear" ? (
        <Foootwear className="footwear-icon" />
      ) : props.Icon === "dcor-lights" ? (
        <ChairIcon />
      ) : null}
    </>
  );
};
export default CategoryIcon;