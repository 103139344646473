import React, { useState, useEffect } from "react";
import CategoryBanner from "./category-banner";
import Filters from "./filters.js";
import CategoryList from "./category-list.js";
import HonestarApis from "../../Apis/Api.js";
import "./index.css";
import { CircularProgress } from "@mui/material";

const Category = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [brandsList, setBrandsList] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [categories, setCategories] = useState(null);
  const [filteredBrandListArray, setFilteredBrandListArray] = useState([]);
  useEffect(() => {
    let urlCrop = window.location.href.split("category/")[1];
    let tempCategoryValue = urlCrop.split("?id=")[0];
    let tempCategoryId = urlCrop.split("?id=")[1];
    if(categoryId === null) {
      setCategoryValue(tempCategoryValue);
      setCategoryId(tempCategoryId);
    }
    console.log(categoryId);
    getCategoryBrandsList();
    getAllCategoriesAndReviews();
  }, [categoryId]);
  const getCategoryBrandsList = () => {
    setIsLoading(true);
    setFilteredBrandListArray(null)
    let params = `?category_id=${categoryId}`;
    HonestarApis.getCategoryBrandsList(params)
      .then((data) => {
        setIsLoading(false);
        setBrandsList(data);
        setFilteredBrandListArray(data);
        console.log(data);
      })
      .catch((error) => setIsLoading(false));
  };
  const getAllCategoriesAndReviews = () => {
    setIsLoading(true);
    HonestarApis.getAllCategoriesAndReviews()
      .then((data) => {
        setIsLoading(false);
        setCategories(data.category);
      })
      .catch((error) => setIsLoading(false));
  };
  const filteredBrandList = (stars) => {
    setFilteredBrandListArray(null);
    let listOfBrands = [...brandsList];
    let filteredArray = listOfBrands.filter(review => review.reviews_avg >= stars);
    setFilteredBrandListArray([...filteredArray]);
    console.log(filteredBrandListArray);
  }
  return (
    <>
      <div className="category-page">
        {categories ? <CategoryBanner categories={categories} /> : null}
        <div className="category-main">
          <div className="category-main-inner">
            {categoryValue && categoryId && categories ? (
              <Filters setCategoryId={setCategoryId} categoryName={categoryValue} categoryId={categoryId} categories={categories} filteredBrandList={filteredBrandList} />
            ) : null}
            {filteredBrandListArray ? <CategoryList brandsList={filteredBrandListArray} getCategoryBrandsList={getCategoryBrandsList} /> : 
            <div className="tc">
            <CircularProgress color="primary" />
          </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
