import { Rating, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ReactComponent as FilledStar } from "../../Assets/filled-star.svg";
import { ReactComponent as EmptyStar } from "../../Assets/empty-star.svg";
import { ReactComponent as SortByIcon } from "../../Assets/sort-by.svg";
import NoImage from "../../Assets/no-image.svg";
import Popup from "../Reuseable-Components/popup.js"
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const CategoryMain = ({ cursor, setCursor, dataFromApi, dataFromApiWithRating, nextCursor, prevCursor, setCursorValue, sortValue, setSortValue, prevCursorArray, setPrevCursorArray, currentCursor, setCurrentCursor }) => {
  const [popupToggle, setPopupToggle] = useState({
    media: false,
    reply: false,
    full_review: false
  })
  const [mediaImage, setMediaImage] = useState({})
  const [replyContent, setReplyContent] = useState({})
  const [fullReviewContent, setFullReviewContent] = useState({})
  const [sortByDropdown, setSortByDropdown] = useState(false)
  const handleMedia = (key, image) => {
    console.log(image)
    Object.keys(popupToggle).forEach((v) => (popupToggle[v] = false));
    setPopupToggle({ ...popupToggle, [key]: true });
    mediaImage.src = image;
    setMediaImage(mediaImage)
    console.log("popupToggle", popupToggle)
    console.log("mediaImage", mediaImage)
  }
  const handleReply = (key, answer, created) => {
    console.log(key)
    Object.keys(popupToggle).forEach((v) => (popupToggle[v] = false));
    setPopupToggle({ ...popupToggle, [key]: true });
    replyContent.answer = answer;
    replyContent.created = created;
    replyContent.brand_name = dataFromApi.client_name;
    setReplyContent({ ...replyContent })
    console.log("popupToggle", popupToggle)
    console.log("replyContent", replyContent)
  }
  const handleFullReview = (key, review, created, firstName, lastName) => {
    console.log(key)
    // popupToggle.media = false;
    // popupToggle.reply = true;
    Object.keys(popupToggle).forEach((v) => (popupToggle[v] = false));
    setPopupToggle({ ...popupToggle, [key]: true });
    fullReviewContent.review = review;
    fullReviewContent.created = created;
    fullReviewContent.first_name = firstName;
    fullReviewContent.last_name = lastName;
    setFullReviewContent({ ...fullReviewContent })
    // setPopupToggle({ ...popupToggle });
    console.log("popupToggle", popupToggle)
    console.log("replyContent", replyContent)
  }
  const handleChange = (event) => {
    setSortValue(event.target.value);
    setTimeout(function () {
      setSortByDropdown(false)
    }, 500)
  }
  useEffect(() => {
    // console.log("cursor", cursor)
  });
  return (
    <>
      <div className="brand-details">
        <div className="brand-details-inner">
          <div className="brand-rating-details">
            <div className="rating-container">
              <div className="rating-container-inner">
                <div className="rating-container-top">
                  <div className="review-avg-rating">
                    <p>
                      Reviews
                      <FilledStar />
                      <span>{dataFromApi?.brand_detail?.reviews_avg?.toFixed(1)}</span>
                    </p>
                  </div>
                  <div className="total-review-and-label">
                    <span className="review-count" review-count>
                      {" "}
                      {dataFromApi?.brand_detail?.reviews_count}{" "}
                    </span>
                    <label> Reviews </label>
                  </div>
                </div>

                {Object.keys(dataFromApi).length > 0 && <>
                  <div className="rating-container-bottom">
                    <div className="rating-star-5">
                      <label> 5-star </label>
                      <span>
                        {" "}
                        <span
                          className="progress-width"
                          style={{
                            width:
                              Math.round(
                                dataFromApi?.summary[4]?.perc
                              ) + "%",
                          }}
                        ></span>{" "}
                      </span>
                      <p className="star-count">
                        {Math.round(
                          (dataFromApi?.summary[4]?.perc)
                        ) + "%"}
                      </p>
                    </div>
                    <div className="rating-star-4">
                      <label> 4-star </label>
                      <span>
                        {" "}
                        <span
                          className="progress-width"
                          style={{
                            width:
                              Math.round(
                                dataFromApi?.summary[3]?.perc
                              ) + "%",
                          }}
                        ></span>{" "}
                      </span>
                      <p className="star-count">
                        {" "}
                        {Math.round(
                          (dataFromApi?.summary[3]?.perc)
                        ) + "%"}
                      </p>
                    </div>
                    <div className="rating-star-3">
                      <label> 3-star </label>
                      <span>
                        {" "}
                        <span
                          className="progress-width"
                          style={{
                            width:
                              Math.round(
                                dataFromApi?.summary[2]?.perc
                              ) + "%",
                          }}
                        ></span>{" "}
                      </span>
                      <p className="star-count">
                        {" "}
                        {Math.round(
                          (dataFromApi?.summary[2]?.perc)
                        ) + "%"}
                      </p>
                    </div>
                    <div className="rating-star-2">
                      <label> 2-star </label>
                      <span>
                        {" "}
                        <span
                          className="progress-width"
                          style={{
                            width:
                              Math.round(
                                dataFromApi?.summary[1]?.perc
                              ) + "%",
                          }}
                        ></span>{" "}
                      </span>
                      <p className="star-count">
                        {" "}
                        {Math.round(
                          (dataFromApi?.summary[1]?.perc)
                        ) + "%"}
                      </p>
                    </div>
                    <div className="rating-star-1">
                      <label> 1-star </label>
                      <span>
                        {" "}
                        <span
                          className="progress-width"
                          style={{
                            width:
                              Math.round(
                                dataFromApi?.summary[0]?.perc
                              ) + "%",
                          }}
                        ></span>{" "}
                      </span>
                      <p className="star-count">
                        {" "}
                        {Math.round(
                          (dataFromApi?.summary[0]?.perc)
                        ) + "%"}
                      </p>
                    </div>
                  </div>
                </>
                }
                <div className="sort-by">
                  <div className="sort-by-inner">
                    <label> Sort: </label>
                    <div className="sort-by-container">
                      <p className="selected-value" onClick={(e) => setSortByDropdown(!sortByDropdown)}>
                        {sortValue === "most_relevant" ?
                          "Most relevant"
                          : sortValue === "most_recent" ?
                            "Most recent"
                            : sortValue === "media" ?
                              "Media"
                              : null
                        }
                        <SortByIcon />
                      </p>
                      <div className={sortByDropdown ? "sort-by-options active" : "sort-by-options"}>
                        <FormControl className="sort-by-formcontrol">
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={sortValue}
                            onChange={handleChange}
                          >
                            <FormControlLabel value="most_relevant" control={<Radio />} label="Most relevant" />
                            <FormHelperText>Show reviews that are more detailed, recent, and easier to read.</FormHelperText>
                            <FormControlLabel value="most_recent" control={<Radio />} label="Most recent" />
                            <FormControlLabel value="media" control={<Radio />} label="Media" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="brand-list">
            <div className="brand-list-inner">
              {dataFromApiWithRating.results?.map((item, key) => (
                <div className="review-item" key={key}>
                  <div className="review-item-inner">
                    <div className="review-item-top">
                      <div className="product-image">
                        <img src={item.product.image ? item.product.image : NoImage} alt={item.product.title} />
                      </div>
                      <div className="product-title-and-rating">
                        <h3 className="product-title">
                          {" "}
                          {item.product.title}{" "}
                          {/* changed */}
                        </h3>
                        <div className="review-rating">
                          <Rating
                            name="read-only"
                            value={Math.round(item.stars)} //changed
                            size="small"
                            icon={<FilledStar fontSize="inherit" />}
                            emptyIcon={<EmptyStar fontSize="inherit" />}
                            readOnly
                          />
                          <p>{item?.stars?.toFixed(1)}</p>
                          {/* changed */}
                        </div>
                      </div>
                    </div>
                    <div className="review-item-center">
                      <p className="review-title"> {item.title}</p>
                      <p className="review-description" onClick={(e) => {
                        handleFullReview("full_review", item.description, item.created_at, item.customer.first_name, item.customer.last_name)
                      }}>
                        {" "}
                        <Tooltip title={"click to read full review"} placement="top">
                          {item.description}{" "}
                        </Tooltip>
                      </p>
                      <div className="review-media">
                        <div className="review-media-inner">
                          {item?.submission_images?.map((item1, key1) => (
                            <div className="review-media-item" key={key1} onClick={(e) => { handleMedia("media", item1.image) }}>
                              <img src={item1.image} alt={item1.title} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="review-item-bottom">
                      <div className="reviewed-by">
                        <p className="reviewed-label">
                          {" "}
                          Reviewed by: <span> {item.customer.first_name + " " + item.customer.last_name} </span>
                        </p>
                        <span className="review-date"> {item.created_at} </span>
                      </div>
                      {
                        item?.answer ?
                          <div className="view-reply">
                            <span name="reply" onClick={(e) => { handleReply("reply", item.answer, item.created_at) }}> View Reply </span>
                          </div>
                          : null
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {prevCursor || nextCursor ?
              <div className="pagination">
                <div className={prevCursor ? "prev" : "prev disabled"}>
                  <ArrowBackIosNewIcon onClick={(e) => {
                    setCursorValue(prevCursorArray[currentCursor - 1])
                    setCurrentCursor(currentCursor - 1)
                    setPrevCursorArray(prevArray => prevArray.slice(0, -1))
                    setCursor(!cursor)
                  }} />
                </div>
                <div className={nextCursor ? "next" : "next disabled"}>
                  <ArrowForwardIosIcon onClick={(e) => {
                    setCursorValue(nextCursor)
                    setPrevCursorArray(prevArray => [...prevArray, nextCursor]);
                    setCurrentCursor(currentCursor + 1)
                    setCursor(!cursor)
                  }} />
                </div>
              </div>
              : null}
          </div>
        </div>
      </div>
      {popupToggle.media || popupToggle.reply || popupToggle.full_review ?
        <Popup popupToggle={popupToggle} setPopupToggle={setPopupToggle} mediaImage={mediaImage} replyContent={replyContent} fullReviewContent={fullReviewContent} />
        : null}
    </>
  );
};
export default CategoryMain;
