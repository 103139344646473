import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import ContactForm from "./contact-form";
import { ReactComponent as Logo } from "../../Assets/Honestar_logo.svg";
import { Link } from 'react-router-dom';
import "./index.css";
const Header = () => {
  const [contactDialog, setContactDialog] = useState(false);
  return (
    <>
      <Grid container className="header">
        <Grid container className="header-inner">
          <Grid item className="honestar-logo">
            <Link to={`/`}>
              <Logo />
            </Link>
          </Grid>
          <Grid item className="honestar-contact">
            <Button onClick={() => setContactDialog(true)}>Contact Us</Button>
          </Grid>
        </Grid>
      </Grid>
      <ContactForm
        contactDialog={contactDialog}
        setContactDialog={setContactDialog}
      />
    </>
  );
};

export default Header;
