import React, { useState, useEffect } from "react";

export default function TermsAndConditions() {
  useEffect(() => {
    window.scroll({
      top: 0
    });
  });
  return (
    <div className="terms-and-condition">
      <div className="terms-and-condition-inner">
        <h1>Terms of Service</h1>
        <p>Coming soon.</p>
      </div>
    </div>
  )
}
